<template>
	<div class="table__switch">
		<div class="table__all">
			<h6 cla5s="table__title">All editor</h6>
			<CustomTable
				:show-filter-search="false"
				:show-filter-received-time="false"
				:show-search-button="false"
				:show-total-items="false"
				:show-item-per-page="false"
				:no-query="true"
				:recent-filter="recentFilters"
				class="table__editor py-3"
			>
				<template #body>
					<template v-if="editors && editors.length">
						<tr v-for="item in editors" :key="item.id">
							<td scope="row" class="text-center fit">
								<b-form-checkbox
									:id="`checkbox-${item.id}`"
									v-model="checkedEditorAll[item.id]"
									:name="`checkbox-${item.id}`"
									class="checkbox"
									@change="slectedEditor($event, item, 'all')"
								/>
							</td>
							<td scope="row" class="text-break text-pre-wrap text-center title">
								{{ item.nickname }}
							</td>
						</tr>
					</template>
					<template v-else>
						<tr class="text-center">
							<td colspan="11" class="fit">
								{{ $t("Table.NoData") }}
							</td>
						</tr>
					</template>
				</template>
				<template #footer>
					<div></div>
				</template>
			</CustomTable>
		</div>
		<div class="table__icon--arrow">
			<div class="text-center">
				<div
					class="table__icon--arrow--right"
					:class="{ 'disable-arrow': isCheckboxAll }"
					@click="handleSelectedEditor"
				>
					<font-awesome-icon :icon="['fas', 'chevron-right']" />
				</div>
			</div>
			<div class="text-center">
				<div
					class="table__icon--arrow--left"
					:class="{ 'disable-arrow': isCheckboxSelected }"
					@click="handleRemoveEditor"
				>
					<font-awesome-icon :icon="['fas', 'chevron-left']" />
				</div>
			</div>
		</div>
		<div class="table__selected">
			<h6 cla5s="table__title">Selected editor</h6>
			<CustomTable
				v-if="selectedEditor && selectedEditor.length"
				:show-filter-search="false"
				:show-filter-received-time="false"
				:show-search-button="false"
				:show-total-items="false"
				:show-item-per-page="false"
				:no-query="true"
				:recent-filter="recentFiltersSelected"
				class="table__editor py-3"
			>
				<template #body>
					<template v-if="selectedEditor && selectedEditor.length">
						<tr v-for="item in selectedEditor" :key="item.id">
							<td scope="row" class="text-center fit">
								<b-form-checkbox
									:id="`checkbox-selected-${item.id}`"
									v-model="checkedEditorSelected[item.id]"
									:name="`checkbox-selected-${item.id}`"
									class="checkbox"
									@change="slectedEditor($event, item, 'selected')"
								/>
							</td>
							<td scope="row" class="text-break text-pre-wrap text-center title">
								{{ item.nickname }}
							</td>
						</tr>
					</template>
					<template v-else>
						<tr class="text-center">
							<td colspan="11" class="fit">
								{{ $t("Table.NoData") }}
							</td>
						</tr>
					</template>
				</template>
				<template #footer>
					<div></div>
				</template>
			</CustomTable>
		</div>
	</div>
</template>

<script>
import { difference } from "lodash-es"
import { createNamespacedHelpers } from "vuex"
import { PAYSHEET_TABLE } from "../../../shared/plugins/constants"
import { SET_EDITORS } from "../../editors/store/mutation-types"
import { SET_EDITOR_SELECTED } from "../store/mutation-types"

const { mapState: editorsState, mapMutations: editorsMutations } = createNamespacedHelpers(
	"editors",
)

const { mapMutations, mapState } = createNamespacedHelpers("paysheet")
export default {
	name: "TableSwitch",
	data() {
		return {
			PAYSHEET_TABLE,
			listSelected: [],
			listRemoveSelected: [],
			isCheckboxSelected: true,
			isCheckboxAll: true,
			checkedEditorAll: {},
			checkedEditorSelected: {},
		}
	},
	computed: {
		...editorsState(["editors", "totalItems", "totalPages", "recentFilters"]),
		...mapState([
			"selectedEditor",
			"totalItemsSelected",
			"totalPagesSelected",
			"recentFiltersSelected",
		]),
	},
	watch: {
		listSelected: {
			handler(val) {
				if (!val.length) {
					this.isCheckboxAll = true
				} else {
					this.isCheckboxAll = false
				}
			},
			deep: true,
		},
		listRemoveSelected: {
			handler(val) {
				if (!val.length) {
					this.isCheckboxSelected = true
				} else {
					this.isCheckboxSelected = false
				}
			},
			deep: true,
		},
	},
	created() {},
	methods: {
		...editorsMutations({ SET_EDITORS }),
		...mapMutations({ SET_EDITOR_SELECTED }),
		handleSelectedEditor() {
			if (this.isCheckboxAll) return

			const newSelectedEditor = [...this.selectedEditor, ...this.listSelected]
			this.SET_EDITOR_SELECTED({
				data: newSelectedEditor,
				removed: false,
				totalAdd: this.listSelected.length,
			})

			const newEditors = difference(this.editors, this.selectedEditor)
			this.SET_EDITORS({ data: newEditors, removed: true, totalRemove: this.listSelected.length })

			this.listSelected = []
			this.checkedEditorAll = {}

			this.removeChecked(this.editors, "checkbox")
		},
		async removeChecked(arr, property) {
			await arr.forEach(el => {
				const item = document.getElementById(`${property}-${el.id}`)
				Boolean(item.checked) && item.click()
			})
			if (!this.listSelected.length) {
				this.isCheckboxAll = true
			} else if (!this.listRemoveSelected.length) {
				this.isCheckboxSelected = true
			} else {
				this.isCheckboxSelected = true
				this.isCheckboxAll = true
			}
		},
		handleRemoveEditor() {
			if (this.isCheckboxSelected) return

			const newEditors = [...this.editors, ...this.listRemoveSelected]
			this.SET_EDITORS({
				data: newEditors,
				removed: false,
				totalAdd: this.listRemoveSelected.length,
			})

			const newSelectedEditor = difference(this.selectedEditor, this.listRemoveSelected)
			this.SET_EDITOR_SELECTED({
				data: newSelectedEditor,
				removed: true,
				totalRemove: this.listRemoveSelected.length,
			})

			this.listRemoveSelected = []
			this.checkedEditorSelected = {}

			this.removeChecked(this.selectedEditor, "checkbox-selected")
		},
		slectedEditor(value, editor, type) {
			if (type === "all") {
				if (value) {
					this.listSelected.push(editor)
				} else {
					const deleteIndex = this.listSelected.findIndex(el => el.name === editor.name)
					this.listSelected.splice(deleteIndex, 1)
				}
			} else {
				if (value) {
					this.listRemoveSelected.push(editor)
				} else {
					const deleteIndex = this.listRemoveSelected.findIndex(el => el.name === editor.name)
					this.listRemoveSelected.splice(deleteIndex, 1)
				}
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.table {
	&__switch {
		display: grid;
		grid-template-columns: 3fr 1fr 3fr;
		height: 100%;
		grid-column-gap: 16px;
	}

	&__all,
	&__selected {
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		padding: 12px;
		max-height: 500px;
		overflow-y: auto;
	}

	&__icon--arrow {
		margin: auto;

		&--right,
		&--left {
			width: 40px;
			height: 40px;
			border-radius: 8px;
			border: 1px solid $border-input;
			margin: 8px auto;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&:hover {
				background: $sidebar-hover-color;
			}
		}
	}
}
.disable-arrow {
	background: $grey-5;
	cursor: not-allowed;

	&:hover {
		background: $grey-5;
	}
}
</style>

<style lang="scss">
.table__editor {
	.card {
		margin-bottom: 0;
	}
	.card-body {
		padding: 0;
	}

	.form-group {
		margin: 0;
	}

	.table {
		overflow-y: hidden;
	}
}
</style>
